<template>
  <div>
    <div class="w-full text-right mr-2">

      <router-link to="/home" class="text-sm md:text-base text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link>
    </div>
    <div class="w-full">
      <br>

      <br>
      <p class="px-2 py-2 rounded border-0 text-white">
        آی پی سرور حراجی ترکیه

      </p>
      <vs-input dir="ltr" type="text" class="text-left" v-model="proxy_ip_tr" ></vs-input>

      <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="applySettings(proxies.proxy_ip_tr, 'proxy_ip_tr')" >
           تغییر آی پی
          </span>

        </vue-button-spinner>



      <p class="px-2 py-2 rounded border-0 text-white">
        آی پی سرور حراجی هلند

      </p>
      <vs-input dir="ltr" type="text" class="text-left" v-model="proxy_ip_nl" ></vs-input>
      <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="applySettings(proxies.proxy_ip_nl, 'proxy_ip_nl')" >
           تغییر آی پی
          </span>

        </vue-button-spinner>

      <p class="px-2 py-2 rounded border-0 text-white">
        آی پی سرور حراجی فرانسه

      </p>
      <vs-input dir="ltr" type="text" class="text-left" v-model="proxy_ip_fr" ></vs-input>
      <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="applySettings(proxies.proxy_ip_fr, 'proxy_ip_fr')" >
           تغییر آی پی
          </span>

        </vue-button-spinner>

      <p class="px-2 py-2 rounded border-0 text-white">
        آی پی سرور حراجی کانادا

      </p>
      <vs-input dir="ltr" type="text" class="text-left" v-model="proxy_ip_ca" ></vs-input>
      <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="applySettings(proxies.proxy_ip_ca, 'proxy_ip_ca')" >
           تغییر آی پی
          </span>

        </vue-button-spinner>


        <p class="px-2 py-2 rounded border-0 text-white">
        آی پی سرور حراجی آلمان

      </p>
      <vs-input dir="ltr" type="text" class="text-left" v-model="proxy_ip_de" ></vs-input>
      <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="applySettings(proxies.proxy_ip_de, 'proxy_ip_de')" >
           تغییر آی پی
          </span>

        </vue-button-spinner>

      <p>


      </p>
    </div>

  </div>
</template>
<script>
import VueButtonSpinner from 'vue-button-spinner'
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
    VueButtonSpinner
  },
  data() {
    return {
      proxies: {
        proxy_ip_tr: '',
        proxy_ip_nl: '',
        proxy_ip_fr: '',
        proxy_ip_ca: '',
        proxy_ip_de: '',
      },
      proxy_ip_tr: '',
      proxy_ip_nl: '',
      proxy_ip_fr: '',
      proxy_ip_ca: '',
      proxy_ip_de: '',
      isLoading: false,
      status: '',
    };
  },
  watch: {

  },
  methods: {
    getSettingKey (id) {
      this.$http.post('/admin/setting/key',{setting_key: id})
        .then(response => {
          this[id] = response.data.params.data.setting_value
          this.proxies[id] = response.data.params.data.id
        })
        .catch(error => {
          console.error(error)
          this[id] = ''
          this.proxies[id] = ''
        })
    },

    applySettings (id,val) {
      this.isLoading = true
      this.$http.post(`/admin/setting/change/${id}`,{setting_value: this[val]})
        .then(response => {
          this.$vs.notify({ text: response.data.message, color: "success" });

          // this.activeGateway = response.data.params.Method
          this.isLoading = false
          this.status = false // or success
          this.getSettingKey(val)

        })
        .catch(error => {

          console.error(error)
          this.$http.post(`/admin/setting/set`,{title: val,setting_key: val,setting_value: this[val]})
          .then(response => {
            this.$vs.notify({ text: response.data.message, color: "success" });

            // this.activeGateway = response.data.params.Method
            this.isLoading = false
            this.status = false // or success
            this.getSettingKey(val)

          })
          this.isLoading = false
          this.status = true //or error
        })
    },
    deleteByValue (key,val,myobject) {
    for(var f in myobject) {
        if(myobject[f][key] === val) {
            myobject[f] = ''
        }
    }
}


  },
  created() {
    this.getSettingKey('proxy_ip_tr')
    this.getSettingKey('proxy_ip_nl')
    this.getSettingKey('proxy_ip_ca')
    this.getSettingKey('proxy_ip_fr')
    this.getSettingKey('proxy_ip_de')

  }
};
</script>
<style>

button:disabled {
      pointer-events:none;
      color: #bfcbd9;
      cursor: not-allowed;
      background-image: none;
      background-color: #eef1f6 !important;
      border-color: #d1dbe5;
    }
</style>
